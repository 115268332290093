.panel-info {
    font-size: 0.85em;
}

.panel-status {
    font-size: 1.05em;
    font-weight: 800;
}

.icon-container :first-child {
    font-size: 48px;
}

@media (min-width: 500px) {
    .panel-info {
        font-size: 1.3em;
    }

    .panel-status {
        font-size: 1.5em;
        font-weight: 800;
    }

    .icon-container :first-child {
        font-size: 64px;
    }
}

@media (min-width: 667px) {
    .panel-info {
        font-size: 1.4em;
    }

    .panel-status {
        font-size: 1.6em;
        font-weight: 800;
    }

    .icon-container :first-child {
        font-size: 96px;
    }
}

@media (min-width: 1200px) {
    .panel-info {
        font-size: 1.5em;
    }

    .panel-status {
        font-size: 1.7em;
        font-weight: 800;
    }

    .icon-container :first-child {
        font-size: 96px;
    }
}