.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-weight: 500;
}

.Home .tables h1 {
  font-weight: 500;
}

span.text-size-h6 {
  font-weight: 400;
}

.Home .lander p {
  color: #999;
}

.expand-btn {
  padding-top: 0;
}

a.pos-info-link:hover, a.pos-info-link:focus {
  color: white;
}

.pwdisplay {
  background: #ffe166;
  border: 1px dashed rgba(0,0,0,0.1);
  font-family: monospace;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  padding: 3px;
  display: inline-block;
  vertical-align: middle;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}